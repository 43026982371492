import React from "react"
import { Router } from "@reach/router"
import { Layout } from "../components/Layout";
import { MedicalPage } from "../components/medical";
import { UserPage } from "../components/user";
import { PrivateRoute } from "../components/Common/privateRoute";
import { Login } from "../components/login";
import { MedicalDepartmentPage } from "../components/medicalDepartment";

const App = () => (
  <Layout>
    <Router basepath={"/app"}>
      <Login path={"/login"} />
      <PrivateRoute Component={MedicalPage} path={"/medical"} />
      <PrivateRoute Component={UserPage} path={"/user"} />
      <PrivateRoute Component={MedicalDepartmentPage} path={"/medical_department"} />
    </Router>
  </Layout>
)

export default App
