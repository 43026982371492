import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import {
  getDepartmentInformationByCSV,
  IMedicalDepartment,
  MedicalCSVHeader,
  MedicalDepartmentCSVHeader
} from "../../utils/medicalDao"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"

export const DepartmentDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<IMedicalDepartment[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const firestore = useFirestore()

  const renderTable = () => {
    return MedicalDepartmentCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {
      const result = await getDepartmentInformationByCSV(firestore)
      setData(result)
      setDone(true)
    }
  }, [ clink, firestore ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
      setDone(false)
    }
  }, [ done ])

  return (
    <>
      <Box style={{ display:"flex", justifyContent: "flex-end" }}>
        <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>医療機関情報ダウンロード</Button>
      </Box>

      <Box className={classes.verticalMargin}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant={"subtitle1"}>
              各列の詳細を確認する
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableBody>
                  {renderTable()}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box style={{ display: "hidden" }}>
        <CSVLink ref={clink} data={data} headers={MedicalDepartmentCSVHeader} filename={"medical_department.csv"} />
      </Box>
    </>
  )
}
