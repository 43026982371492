/**
 * 予約可能時間
 */
export const RESERVE_TIME = {
  "00:00": 0, "00:30": 1,
  "01:00": 2, "01:30": 3,
  "02:00": 4, "02:30": 5,
  "03:00": 6, "03:30": 7,
  "04:00": 8, "04:30": 9,
  "05:00": 10, "05:30": 11,
  "06:00": 12, "06:30": 13,
  "07:00": 14, "07:30": 15,
  "08:00": 16, "08:30": 17,
  "09:00": 18, "09:30": 19,
  "10:00": 20, "10:30": 21,
  "11:00": 22, "11:30": 23,
  "12:00": 24, "12:30": 25,
  "13:00": 26, "13:30": 27,
  "14:00": 28, "14:30": 29,
  "15:00": 30, "15:30": 31,
  "16:00": 32, "16:30": 33,
  "17:00": 34, "17:30": 35,
  "18:00": 36, "18:30": 37,
  "19:00": 38, "19:30": 39,
  "20:00": 40, "20:30": 41,
  "21:00": 42, "21:30": 43,
  "22:00": 44, "22:30": 45,
  "23:00": 46, "23:30": 47,
} as const
export type ReserveTimeType = typeof RESERVE_TIME[keyof typeof RESERVE_TIME]
export const ReserveTimeKey = (value: any) => {
  // @ts-ignore
  return Object.keys(RESERVE_TIME).find(key => RESERVE_TIME[key] === value)
}

/**
 * 表示時間
 */
export const DISPLAY_TIME = {
  "00:00": 0, "00:30": 1,
  "01:00": 2, "01:30": 3,
  "02:00": 4, "02:30": 5,
  "03:00": 6, "03:30": 7,
  "04:00": 8, "04:30": 9,
  "05:00": 10, "05:30": 11,
  "06:00": 12, "06:30": 13,
  "07:00": 14, "07:30": 15,
  "08:00": 16, "08:30": 17,
  "09:00": 18, "09:30": 19,
  "10:00": 20, "10:30": 21,
  "11:00": 22, "11:30": 23,
  "12:00": 24, "12:30": 25,
  "13:00": 26, "13:30": 27,
  "14:00": 28, "14:30": 29,
  "15:00": 30, "15:30": 31,
  "16:00": 32, "16:30": 33,
  "17:00": 34, "17:30": 35,
  "18:00": 36, "18:30": 37,
  "19:00": 38, "19:30": 39,
  "20:00": 40, "20:30": 41,
  "21:00": 42, "21:30": 43,
  "22:00": 44, "22:30": 45,
  "23:00": 46, "23:30": 47,
  "24:00": 48
} as const
export type DisplayTimeType = typeof DISPLAY_TIME[keyof typeof DISPLAY_TIME]
export const DisplayTimeKey = (value: DisplayTimeType | number) => {
// @ts-ignore
  return Object.keys(DISPLAY_TIME).find(key => DISPLAY_TIME[key] === value)
}

/**
 * パーミッション
 */
export const PERMISSION = {
  ADMINISTRATOR: 0,
  CLINIC_ADMIN: 1,
  DOCTOR: 2,
  CLINIC_DESK: 3,
  PHARMACY_ADMIN: 4,
  PHARMACIST: 5,
  PHARMACY_DESK: 6,
  CUSTOMER: 7,
} as const
export type PermissionType = typeof PERMISSION[keyof typeof PERMISSION]

/**
 * 機関タイプ
 */
export type INSTITUTION = {
  CLINIC: 0,
  PHARMACY: 1
}

/**
 * 送信範囲
 */
export const SEND_TYPE = {
  UNICAST: 0,
  MULTICAST: 1
}
export type SendType = typeof SEND_TYPE[keyof typeof SEND_TYPE]

/**
 * 性別定数
 */
export const GENDER = {
  MALE: 1,
  FEMALE: 2,
} as const
export type GenderType = typeof GENDER[keyof typeof GENDER]

/**
 * 予約進行状況
 */
export const RESERVE_STATUS = {
  EMPTY: 0,
  RESERVE: 1,
  WAITING: 2,
  DOCTOR_WAITING: 3,
  START: 4,
  END: 5,
  EXIT_ROOM: 70,
  CANCEL_BY_CUSTOMER: 80,
  CANCEL_BY_DOCTOR: 81,
  CANCEL_BY_SYSTEM: 82,
  PAID: 90,
  FAILED: 91
}
export type ReserveStatusType = typeof RESERVE_STATUS[keyof typeof RESERVE_STATUS]

/**
 * 診療タイプ
 */
export const RESERVE = {
  CLINIC_NOW: 0,
  CLINIC_RESERVE: 1,
  PHARMACY_RESERVE: 2,
  VISIT_RESERVE: 3
}
export type ReserveType = typeof RESERVE[keyof typeof RESERVE]

/**
 * お薬配送方法
 */
export const MEDICINE_RECEIVE_TYPE = {
  MAIL: 0,
  RECEIPT: 1,
  BIKE: 2
} as const
export type MedicineReceiveType = typeof MEDICINE_RECEIVE_TYPE[keyof typeof MEDICINE_RECEIVE_TYPE]

/**
 * 支払方法ステータス
 */
export const PAYMENT_METHOD = {
  CREDIT_CARD: 0,
  PAY_AFTER: 1,
}
export type PaymentMethod = typeof PAYMENT_METHOD[keyof typeof PAYMENT_METHOD]

/**
 * 統計情報表示用予約取得リクエスト
 */
export interface IReserveForAnalyticsRequest {
  from: number
  to: number
}

/**
 * 統計情報表示用予約取得レスポンス
 */
export interface IReserveForAnalyticsResponse {
  summary: {
    reserve_count: number
    total_amount: number
  }
  scale: {
    time: {
      [hhmm: string]: {
        reserve_count: number
        total_amount: number
      }
    }
    date: {
      [yyyymmdd: string]: {
        reserve_count: number
        total_amount: number
      }
    }
  }
  status: {
    reserved: number
    ended: number
    paid: number
    canceled: number
    failed: number
  }
}

/**
 * 日付指定直近予約可能リクエスト
 */
export interface IMedicalSearchRequest {
  type: InstitutionType
  search?: string
  prefecture?: string
  department?: string
  rate_to?: number
  rate_from?: number
  date?: number
  from?: number
  to?: number
  first_visit?: boolean
  geo?: {
    range: number
    latitude: number
    longitude: number
  }
  page?: number
}

/**
 * 勤務時間オブジェクト
 */
export interface IWorkRange {
  no_work: boolean
  shift: boolean[]
}

/**
 * クリニックで対応している診療科情報
 */
export interface IClinicDepartment {
  id: string
  doctors: Array<string>
  description: string,
  sort_no?: number,
  in_hospital_prescription?: boolean,
}

export interface IDayOfWeek {
  mon: boolean,
  tue: boolean,
  wed: boolean,
  thu: boolean,
  fri: boolean,
  sat: boolean,
  sun: boolean,
}

/**
 * chat情報
 */
export interface IMessage {
  created_at: number
  from: string
  message: string
}

/**
 * クリニックレコード
 */
export interface IClinicRecord {
  id?: string,
  store_id?: string,
  departments: IClinicDepartment[],
  rate: number,
  type: INSTITUTION,
  name: string,
  tel?: string,
  fax?: string,
  url?: string,
  tags?: string[],
  post_code: string,
  prefecture: string,
  address1: string,
  address2: string,
  description?: string,
  warning?: string,
  main_image?: string,
  start: ReserveTimeType,
  end: DisplayTimeType,
  day_of_week: IDayOfWeek,
  images?: string[],
  search?: any,
  g?: any,
  coordinates?: any,
  today_reservable_count?: number
  first_visit?: boolean
  re_visit?: boolean
}

/**
 * 検索
 */
export interface ISearch {
  id: string
  clinics: Array<string>
}

/**
 * ユーザー共通
 */
export interface IUserCommon {
  id: string
  type?: PermissionType
  name?: string
  name_kana?: string
  gender?: GenderType
  tel?: string
  medical_id?: string
  department_id?: string
  description?: string
  search?: any
  birth_date?: number,
  disabled?: boolean
  updated_at?: any
  post_code?: string
  prefecture?: string
  address1?: string
  address2?: string
  created_at?: number
  corporate_code?: string
}

/**
 * 勤務時間
 */
export interface IWorkRange {
  no_work: boolean,
  shift: boolean[]
}

export interface IAvailableMedical {
  type: InstitutionType
  department?: string,
  limit?: number
}

/**
 * 医師
 */
export interface IDoctor {
  id: string
  type?: PermissionType
  name?: string
  name_kana?: string
  gender?: GenderType
  tel?: string
  medical_id?: string
  department_id: string,
  description?: string
  search?: any
  birth_date?: number
  mon: IWorkRange,
  tue: IWorkRange,
  wed: IWorkRange,
  thu: IWorkRange,
  fri: IWorkRange,
  sat: IWorkRange,
  sun: IWorkRange
}

/**
 * 薬剤師
 */
export interface IPharmacist {
  id: string
  type?: PermissionType
  name?: string
  name_kana?: string
  gender?: GenderType
  tel?: string
  medical_id?: string
  description?: string
  search?: any
  birth_date?: number
  mon: IWorkRange,
  tue: IWorkRange,
  wed: IWorkRange,
  thu: IWorkRange,
  fri: IWorkRange,
  sat: IWorkRange,
  sun: IWorkRange
}

/**
 * セキュア情報
 */
export interface ISecure {
  id: string
  push_token?: string
  device_id?: string
  insurance_card_front?: string
  insurance_card_back?: string
  stripe?: {
    customer_id?: string
    source?: string
  }
  created_at?: any
  updated_at?: any
}

export interface INotification {
  id: string
  to: string | PermissionType
  type: SendType
  message: string
  is_sent: boolean
}

export interface IMonthSchedule {
  [day: string]: IDaySchedule
}

/**
 * 日毎のスケジュール
 */
export interface IDaySchedule {
  [doctorId: string]: {
    [reserveTime: string]: boolean | string
  }
}

/**
 * 予約取得リクエスト
 */
export interface IGetReserve {
  reserve_id: string
}

/**
 * 患者予約リクエスト
 */
export interface ICustomerReserveRequest {
  clinic: {
    date: number
    department_id: string
    doctor_id: string
    first: boolean
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    images?: string[]
    medicine_receive_type: number
    in_hospital_prescription: boolean
    payment_method?: number
  }
  pharmacy: {
    date: number
    doctor_id: string
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    medicine_receive_type: number
    patient_id?: string
    payment_method?: number
  }
}

/**
 * 患者予約リクエスト
 */
export interface IVisitCustomerReserveRequest {
  visit: {
    date: number
    department_id: string
    doctor_id: string
    first: boolean
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    images?: string[]
    prescription_images?: string[]
    medicine_receive_type: number
  }
}

/**
 * 薬局予約リクエスト
 */
export interface IPharmacyReserveRequest {
  clinic_reserve_id: string
  clinic: any
  pharmacy: {
    date: number
    doctor_id: string
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    medicine_receive_type: number
    patient_id?: string
    payment_method?: number
  }
}

/**
 * 予約チェックのレスポンス
 */
export interface IValidateReserveDataResponse {
  isValid: boolean
  message: string
}

/**
 * 患者予約リクエスト（今すぐオンライン診療）
 */
export interface ICustomerNowReserveRequest {
  clinic: {
    department_id: string
    interviews: any[]
    interviews_for_pharmacy?: any[]
    type: ReserveType
    images?: string[]
    medicine_receive_type: number
    waiting_at: number
    pharmacy_id: string
    payment_method?: number
  }
}

/**
 * 患者予約変更リクエスト
 */
export interface IChangeReserveRequest {
  clinic: {
    id: string,
    medical_id: string,
    date: number,
    from: ReserveTimeType,
    doctor_id: string,
    department_id: string,
    medicine_receive_type: number,
  }
  pharmacy: {
    id: string,
    medical_id: string,
    date: number,
    from: ReserveTimeType,
    medicine_receive_type?: number,
  }
}

export interface ISendChangeReserveNotification {
  clinic_reserve_id: string
  pharmacy_reserve_id: string
  before_clinic_reserve: IReserve
  before_pharmacy_reserve: IReserve
}

/**
 * 予約
 */
export interface IReserve {
  id: string
  type: ReserveType
  medical_id: string
  doctor_id: string
  doctor_name: string
  status: ReserveStatusType
  patient_id: string
  patient_name: string
  gender: number
  age: number
  result?: {
    start_time?: number
    end_time?: number
    medical_fee?: number
    delivery_fee?: number
    system_fee?: number
    np_fee?: number
    other_fee?: number
    amount?: number
    charge_id?: string
    tracking_number?: string
    shopTransactionId?: string
    gmoTransactionId?: string
    shipping_company?: string
    slip_number?: string
  }
  interviews: any
  images?: any
  calling?: boolean
  waiting_at?: number
  search?: any
  first?: boolean
  department_id?: string
  pharmacy_id?: string
  date?: number
  from?: ReserveTimeType
  to?: DisplayTimeType
  created_at?: any
  updated_at?: any
  medicine_receive_type?: MedicineReceiveType
  express?: {
    id?: string
    ready_by?: string
    delivery_at?: string
    delivery_status?: number
    anchor_name?: string
    anchor_at?: {
      latitude?: number
      longitude?: number
    }
  }
  no_medicine: boolean
  payment_method?: number
}

/**
 * 機関タイプ
 */
export const INSTITUTION = {
  CLINIC: 0,
  PHARMACY: 1
} as const
export type InstitutionType = typeof INSTITUTION[keyof typeof INSTITUTION]


export interface IMedicalBase {
  id: string,
  store_id?: string,
  type: InstitutionType,
  name: string,
  today_reservable_count?: number
  tel: string,
  fax: string,
  url: string,
  tags: string[],
  rate?: number
  rate_count?: number
  post_code: string,
  prefecture: string,
  address1: string,
  address2: string,
  description: string,
  departments?: {
    id: string
    description: string
    doctors: string[],
    sort_no?: number,
    in_hospital_prescription?: boolean,
  }[]
  warning: string,
  main_image: string,
  main_image_thumbnail: string,
  start: ReserveTimeType,
  end: DisplayTimeType,
  day_of_week: any,
  images: string[],
  search: any,
  pharmacists?: string[]
  first_visit?: boolean
  re_visit?: boolean
  updated_at?: any
  created_at?: any
  holidays?: IHolidays
}

/**
 * 日毎のスケジュール
 */
export interface IHolidays {
  [date: string] : {
    is_clinic : boolean,
    title : string
  }
}

export interface IDepartmentBase {
  id: string,
  name: string,
  type: InstitutionType,
  interview_sheet?: any,
  sort_no?: number
}

/**
 * メッセージ情報
 */
export interface IMessageDoc {
  id: string
  chats?: Array<IMessage>
  users: any
}

/**
 * 予約情報更新リクエスト
 */
export interface IUpdateReserveStatusRequest {
  reserveId: string
  status?: number
}

/**
 * 予約情報更新リクエスト
 */
export interface ICancelPharmacyReserveRequest {
  pharmacyReserveId: string
  clinicReserveId: string
}

/**
 * 予約情報取得リクエスト
 */
export interface IGetReserveListRequest {
}

/**
 * 薬局スケジュール
 */
export interface IPharmacySchedule {
  [medical_id: string]: IPharmacyMonthSchedule
}

/**
 * 薬局の月ごとのスケジュール
 */
export interface IPharmacyMonthSchedule {
  [ym: string]: IPharmacyDaySchedule
}

/**
 * 薬局の日のスケジュール
 */
export interface IPharmacyDaySchedule {
  [day: string]: IPharmacistSchedule
}

/**
 * 薬剤師のスケジュール
 */
export interface IPharmacistSchedule {
  [pharmacist_id: string]: {
    [reserve_time: string]: boolean | string
  }
}

/**
 * シフト情報
 */
export interface IClinicSchedule {
  id: string
}

/**
 * シフト情報（クリニック）
 */
export interface IMedicalSchedule {
  [medical_id: string]: IYMSchedule
}

/**
 * シフト情報（年月）
 */
export interface IYMSchedule {
  [ym: string]: IDepartmentSchedule[]
}

/**
 * シフト情報（年月）
 */
export interface IDepartmentSchedule {
  id?: string | any

  [day: string]: {
    [doctor_id: string]: {
      [reserveTime: string]: boolean | string
    }
  }
}

/**
 * シフト情報更新用
 */
export interface IClinicScheduleRecord {
  medical_id: string,
  ym: string,
  departments?: string[]
}

/**
 * クリニック決済情報
 */
export interface ICaptureFuncValue {
  medical_fee: number,
  delivery_fee: number,
  system_fee: number,
  np_fee: number,
  other_fee: number,
  total_fee: number,
  tracking_number: string,
  shipping_company: string,
  slip_number: string,
  corporate_code: string,
}

/**
 * お急ぎ便用顧客オブジェクト
 */
export interface IExpressCustomer {
  last_name: string
  first_name: string
  business_name?: string
  phone: string
  email?: string
  address_zipcode?: string
  address_prefecture: string
  address_line_1: string
  address_line_2?: string
}

/**
 * お急ぎ便ドライバーオブジェクト
 */
export interface IExpressDriver {
  id: string
  name: string
  phone?: string
  vehicle_type?: string
  location: {
    longitude: number
    latitude: number
    update: string
  }
}

/**
 * お急ぎ便用配送オブジェクト
 */
export interface IExpressPackage {
  service_brand: string
  order_reference: string
  name: string
  price?: string
  number: number
  boxes: number
  height?: number
  width?: number
  depth?: number
  weight?: number
  attention?: string
}

/**
 * お急ぎ便時間枠オブジェクト
 */
export interface IExpressTimeWindow {
  id: string
  starts_at: string | null
  ends_at: string
  expires_at: string
}

/**
 * お急ぎ便ストアオブジェクト
 */
export interface IExpressStore {
  id: string
  id_alias: string
  store_name: string
  store_address_zipcode: string
  store_address_prefecture: string
  store_address_line_1: string
  store_address_line_2: string
  store_phone: string
  store_type: "standalone_store" | "in_mall_store" | "consolidation_store"
  offers_delivery: boolean
  offers_fetches: boolean
  pickup_proof: "barcode" | "signature" | "selfcheck"
  delivery_proof: string
}

/**
 * お急ぎ便ストアオブジェクト(リクエスト用)
 */
export interface IExpressStoreRequest {
  id_alias?: string
  store_name: string
  store_address_zipcode: string
  store_address_prefecture: string
  store_address_line_1: string
  store_address_line_2?: string
  store_phone: string
  store_type?: string
  pickup_proof?: string
  opening_time?: string
  closing_time?: string
}

/**
 * お急ぎ便配送見積もりリクエストオブジェクト
 */
export interface IExpressDeliveryEstimateRequest {
  store_id: string
  ready_by: string
  customer: {
    address_zipcode: string
    address_prefecture?: string
    address_line_1?: string
    address_line_2: string
  }
  packages: IExpressPackage[]
}

/**
 * お急ぎ便配送見積もりレスポンスオブジェクト
 */
export interface IExpressDeliveryEstimateResponse {
  id: string
  object: string
  delivery_windows: IExpressTimeWindow[]
  unavailable_windows?: IExpressTimeWindow[]
  customer: {
    address_zipcode: string
    address_prefecture: string
    address_line_1: string
    address_line_2: string
  }
  store: {
    store_id: string
    store_name: string
    store_address_prefecture: string
    store_address_line_1: string
    store_address_line_2: string
    store_phone: string
    store_type: string
  }
  distance: number
  packages: IExpressPackage[]
}

/**
 * お急ぎ便配送依頼リクエストオブジェクト
 */
export interface IExpressDeliveryRequest {
  store_id: string
  ready_by: string
  delivery_window_id: string
  customer: IExpressCustomer
  packages: IExpressPackage[]
  pickup_comments: string
  destination_comments: string
  until?: string
}

/**
 * お急ぎ便配送依頼レスポンスオブジェクト
 */
export interface IExpressDeliveryResponse {
  id: string
  object: string
  tracking_code: string
  status: string
  ready_by: string
  driver: null | IExpressDriver
  delivered_at: null | string
  delivery_window: IExpressTimeWindow
  customer: IExpressCustomer
  store: {
    store_id: string
    store_name: string
    store_address_prefecture: string
    store_address_line_1: string
    store_address_line_2: string
    store_phone: string
    store_type: string
  }
  pickup_comments: string
  destination_comments: string
  store_signature: string
  customer_signature: string
  packages: IExpressPackage[]
  distance: number
  etc_price: string
  etc_price_reason: string
  exception: null | any
  update: string
}

/**
 * 予約情報更新リクエスト
 */
export interface IIsReservableDepartments {
  medicalId: string
  departmentId: string
}

/**
 * 患者予約リクエスト
 */
export interface IProxyReserveRequest {
  clinic: {
    date: number
    department_id: string
    doctor_id: string
    first: boolean
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    images?: string[]
    medicine_receive_type: number
    in_hospital_prescription: boolean
    payment_method?: number
  }
  pharmacy: {
    date: number
    doctor_id: string
    from: ReserveTimeType
    interviews: any[]
    medical_id: string
    type: ReserveType
    medicine_receive_type: number
    patient_id?: string
    payment_method?: number
  }
  patient_id: string
}

/**
 * 予約カウント
 */
export interface IMedicalCount {
  id: string,
  EMPTY?: number,
  RESERVE?: number,
  WAITING?: number,
  DOCTOR_WAITING?: number,
  START?: number,
  END?: number,
  EXIT_ROOM?: number,
  CANCEL_BY_CUSTOMER?: number,
  CANCEL_BY_DOCTOR?: number,
  CANCEL_BY_SYSTEM?: number,
  PAID?: number,
  FAILED?: number
}

export interface ICheck {
  device_id: string
}

export interface IExecTransactionRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    httpInfo: {
      httpHeader: string,
      deviceInfo: string,
    },
    buyer: {
      shopTransactionId: string,
      shopOrderDate: string,
      fullName?: string,
      fullKanaName?: string,
      zipCode?: string,
      address?: string,
      tel1?: string,
      email1: string,
      billedAmount?: number,
      paymentType: string,
      sex?: number,
      birthday?: string,
      memberRegistDate?: string,
    },
    deliveries: {
      delivery?: {
        deliveryCustomer?: {
          fullName?: string,
          fullKanaName?: string,
          zipCode?: string,
          address?: string,
          tel?: string,
        },
        details: {
          detail: {
            detailName: string,
            detailPrice: number,
            detailQuantity: string,
            detailBrand: string,
          }
        }
      }
    }
  }
}

export interface IExecTransactionResponse {
  result?: boolean,
  shopTransactionId?: string
  gmoTransactionId?: string
}

export interface IExecCancelTransactionRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    kindInfo: {
      updateKind: string,
    },
    buyer: {
      gmoTransactionId: string,
    },
  }
}

export interface IExecModifyTransactionRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    kindInfo: {
      updateKind: string,
    },
    buyer: {
      gmoTransactionId?: string,
      shopTransactionId: string,
      shopOrderDate: string,
      fullName?: string,
      fullKanaName?: string,
      zipCode?: string,
      address?: string,
      tel1?: string,
      email1: string,
      billedAmount?: number,
      paymentType: string,
      sex?: number,
      birthday?: string,
      memberRegistDate?: string,
    },
    deliveries: {
      delivery?: {
        deliveryCustomer?: {
          fullName?: string,
          fullKanaName?: string,
          zipCode?: string,
          address?: string,
          tel?: string,
        },
        details: {
          detail: {
            detailName: string,
            detailPrice: number,
            detailQuantity: string,
            detailBrand: string,
          }
        }
      }
    }
  }
}

export interface IPdRequest {
  request: {
    shopInfo: {
      authenticationId: string,
      shopCode: string,
      connectPassword: string,
    },
    transaction: {
      gmoTransactionId?: string,
      pdcompanycode?: string,
      slipno?: string,
      invoiceIssueDate?: string
    },
  }
}
