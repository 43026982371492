import firebase from "gatsby-plugin-firebase"
import { useEffect, useState } from "react"

export const useFirestore = () => {
  const [ firestore, setFirestore ] = useState<firebase.firestore.Firestore | undefined>(undefined)

  useEffect(() => {
    const temp = firebase.firestore()
    temp.settings({ ignoreUndefinedProperties: true })
    setFirestore(temp)
  }, [ firebase ])

  return firestore
}
